import { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, connect, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import './MediaDetail.scss';
import {
  getMedia,
  updateMedia,
  deleteMedia,
  submitMedia
} from 'redux-layer/actions';
import {
  download3dContent,
  downloadBullet,
  downloadSubmittedData,
  downloadMivSource,
  downloadLog
} from 'helper/utils';
import {
  PROCESSING_STATUS_QUEUE_BULLET,
  PROCESSING_STATUS_SUCCESS,
  PROCESSING_STATUS_SUCCESS_3D,
  PROCESSING_STATUS_PROCESSING_BULLET,
  PROCESSING_STATUS_FAILED_BULLET,
  PROCESSING_STATUS_FAILED_3D,
  GENERATE_3D,
  GENERATE_ALL,
  GENERATE_BULLET,
  GENERATE_SOURCE,
  GENERATE_AI,
  GENERATE_VIDEO
} from 'helper/constants';
import {
  CAPTURE_TYPE_3DVIDEO_VIMMERSE,
  CAPTURE_TYPE_IPHONE_RECORD3D,
  CAPTURE_TYPE_IPHONE_VIMMERSE,
  CAPTURE_TYPE_MULTI_CAMERA_MIV,
  CAPTURE_TYPE_MULTI_KINECT_DEPTH_KIT,
  CAPTURE_TYPE_MULTI_KINECT_OPEN3D
} from 'helper/constants';
import { ActionRequiredDialog } from 'components/ActionRequiredDialog';
import { NotificationManager } from 'react-notifications';
import { CopyContentDialog } from 'components/CopyContentDialog';
import { SuccessContentViewer } from './SuccessContentViewer';
import { VimmerseMediaDetail } from './VimmerseMediaDetail';
import { FreeportMediaDetail } from './FreeportMediaDetail';

const MediaDetail = props => {
  const dispatch = useDispatch();
  const { media_id } = useParams();
  const navigate = useNavigate();
  const [showCopyContent, setShowCopyContent] = useState(false);
  const [showActionRequired, setShowActionRequired] = useState(false);

  const { allMedia, allCustomers } = useSelector(state => ({
    allMedia: state.media.allMedia,
    allCustomers: state.customers.allCustomers
  }));

  const currentMedia = allMedia[media_id];

  const customersOptions = Object.values(allCustomers).map(co => ({
    value: co.id,
    label: co.name,
    customer_id: co.customer_id,
    customer_key: co.key || ''
  }));

  useEffect(() => {
    dispatch(getMedia(media_id));
  }, []);

  useEffect(() => {
    if (allCustomers) {
      if (currentMedia) {
        if (customersOptions.length) {
          const currentCustomer = customersOptions.find(cc => cc.customer_id === currentMedia.customer_id);
          localStorage.setItem('customer', JSON.stringify(currentCustomer));
        }
      }
    }
  }, [currentMedia, allCustomers]);

  if (!currentMedia) {
    return '';
  }

  const onDeleteMedia = () => {
    if (window.confirm('Do you really want to remove this media?')) {
      dispatch(deleteMedia(media_id));
      navigate(-1);
    }
  };

  const onSetActionRequired = payload => {
    dispatch(updateMedia(media_id, payload));
    setShowActionRequired(false);
  };

  const handleProcess = (type, batch = false) => {
    if (window.confirm('Do you really want to submit')) {
      if ([GENERATE_ALL, GENERATE_AI, GENERATE_SOURCE].includes(type)) {
        const { valid, message } = checkValidation();
        if (!valid) {
          NotificationManager.error('Failed to submit 3d', message);
          return;
        }
      }
      try {
        dispatch(submitMedia(media_id, type, batch));
      } catch (error) {
        console.log(error);
        NotificationManager.error('Failed to submit 3d', '');
      }
    }
  };

  const checkValidation = () => {
    const retData = {
      valid: false,
      message: ''
    };

    if (!currentMedia || !currentMedia.files || !currentMedia.files.length) {
      return retData;
    }
    const uploadedFiles = currentMedia.files;
    const zipFile = uploadedFiles.find(f => f.name.includes('.zip'));
    if (zipFile) {
      retData.message =
        'Please make sure you are uploading files under instructions.';
      retData.valid = true;
      return retData;
    }
    // if (serverType && serverType.value === 'Fast') {
    //   retData.message = 'Quick Render is coming soon.';
    //   return retData;
    // }
    if (!currentMedia.capture_type) {
      retData.message = 'Please select video format to proceed.';
      return retData;
    }
    if (currentMedia.capture_type === CAPTURE_TYPE_MULTI_CAMERA_MIV) {
      const jsonFile = uploadedFiles.find(f => f.name.includes('.json'));
      if (!jsonFile) {
        retData.message =
          'Please upload at least one JSON file for sequence parameters.';
        return retData;
      }

      uploadedFiles.forEach(f => {
        if (
          f.name.slice(-4).toLowerCase() === '.mp4' ||
          f.name.slice(-4).toLowerCase() === '.yuv' ||
          f.name.slice(-5).toLowerCase() === '.json' ||
          f.name.slice(-4).toLowerCase() === '.aac' ||
          f.name.slice(-4).toLowerCase() === '.mov' ||
          f.name.slice(-4).toLowerCase() === '.csv' ||
          f.name.slice(-4).toLowerCase() === '.png' ||
          f.name.slice(-4).toLowerCase() === '.jpg' ||
          f.name.slice(-5).toLowerCase() === '.jpeg' ||
          f.name.slice(-4).toLowerCase() === '.mp3'
        ) {
        } else {
          retData.message =
            'Please upload only MP4, YUV, AAC, MP3 and JSON files.';
        }
      });
      if (retData.message) {
        return retData;
      }
    }
    if (
      currentMedia.capture_type === CAPTURE_TYPE_IPHONE_RECORD3D ||
      currentMedia.capture_type === CAPTURE_TYPE_IPHONE_VIMMERSE ||
      currentMedia.capture_type === CAPTURE_TYPE_MULTI_KINECT_DEPTH_KIT ||
      currentMedia.capture_type === CAPTURE_TYPE_MULTI_KINECT_OPEN3D
    ) {
      const mp4Files = uploadedFiles.filter(f =>
        f.name.toLowerCase().includes('.mp4')
      );
      const movFiles = uploadedFiles.filter(f =>
        f.name.toLowerCase().includes('.mov')
      );
      if (
        mp4Files.length > 1 &&
        currentMedia.capture_type === CAPTURE_TYPE_IPHONE_RECORD3D
      ) {
        retData.message = 'Please upload only 1 MP4 or MOV file.';
      }
      if (
        movFiles.length > 1 &&
        currentMedia.capture_type === CAPTURE_TYPE_IPHONE_RECORD3D
      ) {
        retData.message = 'Please upload only 1 MP4 or MOV file.';
      }
      if (
        mp4Files.length &&
        movFiles.length &&
        currentMedia.capture_type === CAPTURE_TYPE_IPHONE_RECORD3D
      ) {
        retData.message = 'Please upload only 1 MP4 or MOV file.';
      }
      if (
        !mp4Files.length &&
        !movFiles.length &&
        currentMedia.capture_type === CAPTURE_TYPE_IPHONE_RECORD3D
      ) {
        retData.message = 'Please upload only 1 MP4 or MOV file.';
      }
      if (retData.message) {
        return retData;
      }
    }
    if (currentMedia.capture_type === CAPTURE_TYPE_3DVIDEO_VIMMERSE) {
      const mp4Files = uploadedFiles.filter(f =>
        f.name.toLowerCase().includes('.mp4')
      );
      if (mp4Files.length !== 1) {
        retData.message = 'Please upload only 1 Vimmerse 3d video file.';
      }
      if (retData.message) {
        return retData;
      }
    }
    retData.valid = true;
    return retData;
  };

  const { valid } = checkValidation();

  return (
    <div className='session-detail-page'>
      <div className='page-header'>
        <h1>{currentMedia && currentMedia.title}</h1>

        <div className='actions'>
          <Dropdown
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <Dropdown.Toggle variant='primary' id='dropdown-action'>
              Actions
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                eventKey='generate-all'
                onClick={() => handleProcess(GENERATE_ALL)}
                disabled={!valid}
              >
                Generate ALL
              </Dropdown.Item>
              <Dropdown.Item
                eventKey='generate-source'
                onClick={() => handleProcess(GENERATE_SOURCE)}
                disabled={!valid}
              >
                Generate Source
              </Dropdown.Item>
              {currentMedia.source_files &&
                currentMedia.source_files.length > 0 && (
                  <Dropdown.Item
                    eventKey='generate-3d'
                    onClick={() => handleProcess(GENERATE_3D)}
                  >
                    Generate 3D
                  </Dropdown.Item>
                )}
              <Dropdown.Item
                eventKey='generate-bullet-video'
                onClick={() => handleProcess(GENERATE_BULLET)}
              >
                Generate Bullet Video
              </Dropdown.Item>
              <Dropdown.Item
                eventKey='generate-bullet-video'
                onClick={() => handleProcess(GENERATE_VIDEO)}
              >
                Generate Video
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item
                eventKey='batch-generate-all'
                onClick={() => handleProcess(GENERATE_ALL, true)}
              >
                Batch Generate ALL
              </Dropdown.Item>
              <Dropdown.Item
                eventKey='batch-generate-source'
                onClick={() => handleProcess(GENERATE_SOURCE, true)}
              >
                Batch Generate Source
              </Dropdown.Item>
              <Dropdown.Item
                eventKey='batch-generate-3d'
                onClick={() => handleProcess(GENERATE_3D, true)}
              >
                Batch Generate 3D
              </Dropdown.Item>
              <Dropdown.Item
                eventKey='batch-generate-bullet'
                onClick={() => handleProcess(GENERATE_BULLET, true)}
              >
                Batch Generate Bullet Video
              </Dropdown.Item>
              <Dropdown.Divider />
              {[
                PROCESSING_STATUS_SUCCESS_3D,
                PROCESSING_STATUS_QUEUE_BULLET,
                PROCESSING_STATUS_PROCESSING_BULLET,
                PROCESSING_STATUS_FAILED_BULLET,
                PROCESSING_STATUS_FAILED_3D,
                PROCESSING_STATUS_SUCCESS
              ].includes(currentMedia.processing_status) ? (
                <>
                  {currentMedia && (
                    <>
                      <Dropdown.Item
                        eventKey='copy-content'
                        onClick={() => setShowCopyContent(true)}
                      >
                        Copy Media
                      </Dropdown.Item>
                      <Dropdown.Divider />
                    </>
                  )}

                  <Dropdown.Item
                    eventKey='download-3d-video'
                    onClick={() => download3dContent(currentMedia)}
                  >
                    Download 3D Content
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey='download-bullet-video'
                    onClick={() => downloadBullet(currentMedia)}
                  >
                    Download Bullet Video
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item
                    eventKey='download-atlases'
                    onClick={() => downloadSubmittedData(currentMedia)}
                  >
                    Download Submitted Data
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey='download-miv-uploads'
                    onClick={() => downloadMivSource(currentMedia)}
                  >
                    Download Source Views
                  </Dropdown.Item>
                </>
              ) : (
                ''
              )}
              <Dropdown.Item
                eventKey='download-log'
                onClick={() => downloadLog(currentMedia)}
              >
                Download Log
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item
                eventKey='action-required'
                onClick={() => setShowActionRequired(true)}
              >
                Action Required
              </Dropdown.Item>
              <Dropdown.Item eventKey='delete-media' onClick={onDeleteMedia}>
                Force Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      {[
        PROCESSING_STATUS_SUCCESS_3D,
        PROCESSING_STATUS_QUEUE_BULLET,
        PROCESSING_STATUS_PROCESSING_BULLET,
        PROCESSING_STATUS_FAILED_BULLET,
        PROCESSING_STATUS_SUCCESS
      ].includes(currentMedia.processing_status) && (
        <div className='card mb-3'>
          <div className='card-body'>
            <SuccessContentViewer media={currentMedia} />
          </div>
        </div>
      )}
      <VimmerseMediaDetail media={currentMedia} />
      <FreeportMediaDetail media={currentMedia} />

      {showActionRequired && (
        <ActionRequiredDialog
          error_message={currentMedia.error_message}
          onSubmit={onSetActionRequired}
          onClose={() => setShowActionRequired(false)}
        />
      )}
      {showCopyContent && (
        <CopyContentDialog
          id={currentMedia.id}
          contentType="media"
          onClose={() => setShowCopyContent(false)}
        />
      )}
    </div>
  );
};

function mapStateToProps(state) {
  const { media } = state;
  const { allMedia } = media;
  return {
    allMedia
  };
}

const connectedObject = connect(mapStateToProps)(MediaDetail);
export { connectedObject as MediaDetail };
