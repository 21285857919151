import React from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import Select from 'react-select';

const AddUserModal = ({ showAddUserModal, setShowAddUserModal, newUser, setNewUser, handleAddUser }) => {
  const roleOptions = [
    { value: 'Admin', label: 'Admin' },
    { value: 'Viewer', label: 'Viewer' }
  ];

  return (
    <Modal show={showAddUserModal} onHide={() => setShowAddUserModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Add New User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId='formUsername'>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type='text'
              placeholder='Enter email'
              value={newUser.email}
              onChange={e =>
                setNewUser({ ...newUser, email: e.target.value })
              }
            />
          </Form.Group>
          <Form.Group controlId='formRole'>
            <Form.Label>Role</Form.Label>
            <Select
              options={roleOptions}
              value={roleOptions.find(option => option.value === newUser.role)}
              onChange={option =>
                setNewUser({ ...newUser, role: option.value })
              }
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='secondary'
          onClick={() => setShowAddUserModal(false)}
        >
          Close
        </Button>
        <Button variant='primary' onClick={handleAddUser}>
          Add User
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddUserModal;
