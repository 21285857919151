import { useState } from 'react';
import {
  Button,
  Modal,
  FormGroup,
  FormLabel,
  FormControl,
  FormText
} from 'react-bootstrap';
import Select from 'react-select';
import { isEqual } from 'lodash';
import './BatchDialog.scss';
import {
  DEFAULT_GENERATE_TYPE,
  DEFAULT_SUBMIT_PARAMS,
  GENERATE_3D,
  GENERATE_ALL,
  GENERATE_BULLET,
  GENERATE_AI,
  GENERATE_SOURCE,
  GENERATE_VIDEO,
  PUBLIC_STATUS_OPTIONS
} from 'helper/constants';
import { getPosePresetsFromOldBatch } from 'helper/utils';

const GENERATE_TYPE_OPTIONS = [
  { value: GENERATE_ALL, label: GENERATE_ALL },
  { value: GENERATE_VIDEO, label: GENERATE_VIDEO },
  { value: GENERATE_BULLET, label: GENERATE_BULLET },
  { value: GENERATE_AI, label: GENERATE_AI },
  { value: GENERATE_SOURCE, label: GENERATE_SOURCE },
  { value: GENERATE_3D, label: GENERATE_3D }
];

const BatchDialog = props => {
  const { batch, onSubmit, onClose } = props;
  const [title, setTitle] = useState(batch.title);
  const [message, setMessage] = useState(batch.description);
  const [note, setNote] = useState(batch.note);
  const [email, setEmail] = useState(batch.email);
  const [industry, setIndustry] = useState(batch.industry);
  const [publicStatus, setPublicStatus] = useState(
    PUBLIC_STATUS_OPTIONS.find(pso => pso.value === batch.visibility_status) ||
      PUBLIC_STATUS_OPTIONS[0]
  );
  const [posePresets, setPosePresets] = useState(
    JSON.stringify(
      batch.pose_preset
        ? batch.pose_preset
        : getPosePresetsFromOldBatch(batch),
      undefined,
      2
    )
  );
  const [submitParams, setSubmitParams] = useState(
    JSON.stringify(
      batch.submit_params ? batch.submit_params : DEFAULT_SUBMIT_PARAMS,
      undefined,
      2
    )
  );
  const [generateType, setGenerateType] = useState(
    GENERATE_TYPE_OPTIONS.find(
      co =>
        co.value ===
        (batch.generate_type ? batch.generate_type : DEFAULT_GENERATE_TYPE)
    )
  );
  const [feedback, setFeedback] = useState(batch?.feedback);
  const [excelLink, setExcelLink] = useState(batch?.excel_link);

  const submitModal = () => {
    const payload = {
      title,
      message,
      email,
      note,
      industry,
      visibility_status: publicStatus.value,
      excel_link: excelLink,
      feedback: feedback
    };
    const oldPosePresets = batch.pose_preset
      ? batch.pose_preset
      : getPosePresetsFromOldBatch(batch);
    const oldSubmitParams = batch.submit_params
      ? batch.submit_params
      : DEFAULT_SUBMIT_PARAMS;
    const oldGenerateType = batch.generate_type
      ? batch.generate_type
      : DEFAULT_GENERATE_TYPE;
    if (!isEqual(JSON.parse(posePresets), oldPosePresets)) {
      payload['pose_preset'] = JSON.parse(posePresets);
    }
    if (!isEqual(JSON.parse(submitParams), oldSubmitParams)) {
      payload['submit_params'] = JSON.parse(submitParams);
    }
    payload['generate_type'] = generateType.value;

    onSubmit(payload);
  };

  const isValidJSON = val => {
    try {
      JSON.parse(val);
    } catch (error) {
      return false;
    }
    return true;
  };

  return (
    <Modal show={true} onHide={onClose} backdrop='static' size='lg'>
      <Modal.Header>{batch ? batch.title : 'New batch'}</Modal.Header>
      <Modal.Body>
        <FormGroup className='mb-3'>
          <FormLabel>Title</FormLabel>
          <FormControl
            type='text'
            value={title}
            placeholder='Title'
            onChange={e => setTitle(e.target.value)}
          />
        </FormGroup>
        <FormGroup className='mb-3'>
          <FormLabel>Message From User</FormLabel>
          <FormControl
            as='textarea'
            rows={3}
            value={message}
            placeholder='message'
            onChange={e => setMessage(e.target.value)}
          />
        </FormGroup>
        <FormGroup className='mb-3'>
          <FormLabel>Email</FormLabel>
          <FormControl
            type='email'
            value={email}
            placeholder='Email'
            onChange={e => setEmail(e.target.value)}
          />
        </FormGroup>
        <FormGroup className='mb-3'>
          <FormLabel>Industry</FormLabel>
          <FormControl
            type='text'
            value={industry}
            placeholder='Industry'
            onChange={e => setIndustry(e.target.value)}
          />
        </FormGroup>
        <FormGroup className='mb-3'>
          <FormLabel>Visibility</FormLabel>
          <Select
            options={PUBLIC_STATUS_OPTIONS}
            value={publicStatus}
            onChange={setPublicStatus}
          />
        </FormGroup>
        <FormGroup className='mb-3'>
          <FormLabel>Pose Presets</FormLabel>
          <FormControl
            as='textarea'
            rows={5}
            value={posePresets}
            placeholder='Pose Presets'
            onChange={e => setPosePresets(e.target.value)}
          />
          <FormText className='text-muted'>
            Please input valid pose preset object or array of pose preset.
          </FormText>
          <div className='mt-2'>
            {isValidJSON(posePresets) ? (
              <span className='text-success'>Json is Valid</span>
            ) : (
              <span className='text-danger'>Json is not Valid</span>
            )}
          </div>
        </FormGroup>
        <FormGroup className='mb-3'>
          <FormLabel>Submit Params</FormLabel>
          <FormControl
            as='textarea'
            rows={5}
            value={submitParams}
            placeholder='Submit Params'
            onChange={e => setSubmitParams(e.target.value)}
          />
          <FormText className='text-muted'>
            Please input valid submit params object.
          </FormText>
          <div className='mt-2'>
            {isValidJSON(submitParams) ? (
              <span className='text-success'>Json is Valid</span>
            ) : (
              <span className='text-danger'>Json is not Valid</span>
            )}
          </div>
        </FormGroup>
        <FormGroup className='mb-3'>
          <FormLabel>Generate Type</FormLabel>
          <Select
            options={GENERATE_TYPE_OPTIONS}
            value={generateType}
            onChange={setGenerateType}
          />
        </FormGroup>
        <FormGroup className='mb-3'>
          <FormLabel>Note</FormLabel>
          <FormControl
            as='textarea'
            rows={3}
            value={note}
            placeholder='Note'
            onChange={e => setNote(e.target.value)}
          />
        </FormGroup>
        {/* <FormGroup className='mb-3'>
          <FormLabel>Excel Link</FormLabel>
          <FormControl
            as='textarea'
            rows={3}
            value={excelLink}
            placeholder='Excel Link'
            onChange={e => setExcelLink(e.target.value)}
          />
        </FormGroup> */}
        <FormGroup className='mb-3'>
          <FormLabel>Feedback from User</FormLabel>
          <FormControl
            as='textarea'
            readonly
            rows={3}
            value={feedback}
            placeholder='Feedback'
            onChange={e => setFeedback(e.target.value)}
          />
        </FormGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='primary' onClick={submitModal}>
          Save
        </Button>
        <Button variant='secondary' onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export { BatchDialog };
