import { actionConstants } from 'redux-layer/constants';
import {
  startReqActionType,
  successReqActionType,
  failReqActionType,
  apiHeader,
  processResponse
} from 'helper/requestHelper';
import { getEnvironment } from 'helper/utils';
import { NotificationManager } from 'react-notifications';

export const paginatedListAssets = (params, reset) => async dispatch => {
  dispatch({ type: startReqActionType(actionConstants.PAGINATED_LIST_ASSETS) });
  const requestOptions = {
    method: 'GET',
    headers: apiHeader()
  };

  try {
    const response = await fetch(
      `${getEnvironment().API_URL}assets?${params}`,
      requestOptions
    );
    const data = await processResponse(response);
    dispatch({
      type: successReqActionType(actionConstants.PAGINATED_LIST_ASSETS),
      payload: { ...data, reset }
    });
  } catch (error) {
    dispatch({
      type: failReqActionType(actionConstants.PAGINATED_LIST_ASSETS)
    });
  }
};

export const getAsset = (assetId, reset) => async dispatch => {
  dispatch({ type: startReqActionType(actionConstants.GET_ASSET_DETAIL) });
  const requestOptions = {
    method: 'GET',
    headers: apiHeader()
  };

  try {
    const response = await fetch(
      `${getEnvironment().API_URL}asset/${assetId}`,
      requestOptions
    );
    const data = await processResponse(response);
    dispatch({
      type: successReqActionType(actionConstants.GET_ASSET_DETAIL),
      payload: { ...data, reset }
    });
  } catch (error) {
    dispatch({
      type: failReqActionType(actionConstants.GET_ASSET_DETAIL)
    });
  }
};

export const copyAsset = payload => async dispatch => {
  const requestOptions = {
    method: 'POST',
    headers: apiHeader(),
    body: JSON.stringify(payload)
  };

  const url = `${getEnvironment().API_URL}assets/copy`;

  try {
    const data = await fetch(url, requestOptions);
    const retData = await processResponse(data);
    dispatch({
      type: successReqActionType(actionConstants.COPY_CONTENT),
      payload: retData
    });
    NotificationManager.success(
      'New ID of copied content is ' + retData.id,
      'Succeed to Copy Content',
      3000,
      () => {
        document.location.href = '/assets/' + retData.id;
      }
    );
  } catch (error) {
    dispatch({
      type: failReqActionType(actionConstants.COPY_CONTENT),
      error: error.message
    });
  }
};
