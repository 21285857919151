import { useState, useEffect, version } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import Select from 'react-select';
import { AdvancedEditDialog } from '../../components/AdvancedEditDialog/AdvancedEditDialog';
import 'react-dropzone-uploader/dist/styles.css';
import {
  getFileUploadURL,
  removeUploadedMediaFile,
  updateMedia,
  downloadUploadedMediaFile
} from 'redux-layer/actions';
import {
  getEnvironment,
  getStatusText,
  humanFileSize,
  replaceSpecialCharNaming,
  copyToClipboard
} from 'helper/utils';
import {
  UPLOAD_TO_SOURCE,
  UPLOAD_TO_UPLOAD,
  FILE_TYPE_OPTIONS
} from 'helper/constants';
import { useDropzone } from 'react-dropzone';
import { CAPTURE_OPTIONS } from 'helper/constants';
import { NotificationManager } from 'react-notifications';
import { NavLink } from 'react-router-dom';

const FreeportMediaDetail = props => {
  const dispatch = useDispatch();
  const { media } = props;
  const [isUploading, setIsUploading] = useState(false);
  const [preUploadFiles, setPreUploadFiles] = useState([]);

  const [fileMap, setFileMap] = useState({});

  const [showEditDialog, setShowEditDialog] = useState(false);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  useEffect(() => {
    setPreUploadFiles(acceptedFiles);
  }, [acceptedFiles]);

  useEffect(() => {
    if (media) {
      const { files = [], submit_params, file_map } = media;
      const uploadedFileNames = files.map(f => f.name.toLowerCase());

      if (submit_params) {
        let isValid = true;

        let newFileMap = {};

        if (!file_map) {
          isValid = false;
        } else {
          file_map.forEach(f => {
            const savingFileName = replaceSpecialCharNaming(
              f['FileName'].toLowerCase()
            );
            if (uploadedFileNames.includes(savingFileName)) {
              newFileMap[savingFileName] = f['FileType'];
            } else {
              // isValid = false;
            }
          });

          setFileMap(newFileMap);
        }

        // if (!file_map || !isValid) {
        //   const newFileMap = autoDetectFileType(uploadedFileNames);
        //   setFileMap(newFileMap);
        // }
      } else {
        // const newFileMap = autoDetectFileType(uploadedFileNames);
        // setFileMap(newFileMap);
      }
    }
  }, [media]);

  useEffect(() => {
    if (Object.keys(fileMap).length) {
      if (media) {
        const { file_map } = media;

        const files = Object.keys(fileMap).map(fileMapKey => ({
          FileName: fileMapKey,
          FileType: fileMap[fileMapKey]
        }));

        if (!_.isEqual(files, file_map)) {
          const payload = {
            file_map: files
          };

          dispatch(updateMedia(media.id, payload));
        }
      }
    }
  }, [fileMap]);

  if (!media) {
    return '';
  }

  const envString = localStorage.getItem('myEnv');
  let envName = 'prod';
  if (envString) {
    const envValue = JSON.parse(envString);
    envName = envValue.value;
  }

  const s3ResultBucket =
    envName === 'prod' ? 'freeport-results-prod' : 'freeport-results-dev';
  const s3UploadBucket =
    envName === 'prod' ? 'freeport-uploads-prod' : 'freeport-uploads-dev';

  const freeportMediaTable =
    envName === 'prod' ? 'FreeportMedia-prod' : 'FreeportMedia-dev';
  const vimmerseMediaTable =
    envName === 'prod' ? 'VimmerseMedia-prod' : 'VimmerseMedia-dev';

  const handleChangeFileType = (fileName, type) => {
    const newFileMap = {
      ...fileMap,
      [fileName]: type
    };
    setFileMap(newFileMap);
  };

  const { submit_params } = media;

  const currentCaptureFormat =
    CAPTURE_OPTIONS.find(co => co.value === media.capture_type) ||
    CAPTURE_OPTIONS[0];

  const onUpdateMedia = payload => {
    const submit_paramsValues = {
      ...submit_params,
      scale_factor: payload.scale_factor,
      mask_source: payload.mask_source,
      rotation: payload.rotation,
      mask_processing: payload.mask_processing,
      sound_effects: payload.sound_effects,
      erosion_radius: payload.erosion_radius,
      depth_processing: payload.depth_processing,
      background_processing: payload.background_processing,
      stabilize_motion: payload.stabilize_motion,
      virtual_views: payload.virtual_views,
      virtual_motion_amount: payload.virtual_motion_amount,
      renderer_type: payload.renderer_type,
      random_seed: payload.random_seed,
      nsfw_filter_type: payload.nsfw_filter_type,
      pipeline_preset: payload.pipeline_preset
    };

    if (payload.audio_preset) {
      submit_paramsValues['audio_preset'] = payload.audio_preset;
    }

    if (payload.background_preset) {
      submit_paramsValues['background_preset'] = payload.background_preset;
    }

    const updates = {
      capture_type: payload.capture_type,
      error_message: payload.error_message,
      pose_preset: payload.pose_preset,
      submit_params: submit_paramsValues,
      generate_ai: payload.generate_ai
    };
    dispatch(updateMedia(media.id, updates));
    setShowEditDialog(false);
  };

  const uploadFile = async (file, uploadType) => {
    // get presigned URL
    try {
      const response = await getFileUploadURL(
        media.id,
        file.name.toLowerCase(),
        file.type,
        uploadType
      );

      await postFile(file, response);
    } catch (error) {
      // show error message
    }
  };

  const postFile = (file, data) => {
    return new Promise((resolve, reject) => {
      const uploadData = data['data'];
      const formData = new FormData();

      formData.append('AWSAccessKeyId', uploadData['AWSAccessKeyId']);
      formData.append('key', uploadData['key']);
      formData.append('policy', uploadData['policy']);
      formData.append('signature', uploadData['signature']);
      formData.append('file', file);

      const requestOptions = {
        method: 'POST',
        body: formData,
        redirect: 'follow'
      };

      fetch(data['signedURL'], requestOptions)
        .then(response => response.text())
        .then(result => {
          resolve();
          console.log(result);
          NotificationManager.success(
            file.name + ' is uploaded successfully',
            'Uploaded!'
          );
        })
        .catch(error => {
          console.log('error', error);
          reject();
        });
    });
  };

  const onUploadFile = async uploadType => {
    if (preUploadFiles.length) {
      try {
        setIsUploading(true);
        await Promise.all(
          preUploadFiles.map(file => uploadFile(file, uploadType))
        );
      } catch {
      } finally {
        setIsUploading(false);
        setPreUploadFiles([]);
      }
    }
  };

  const copySourceFile = filename => {
    const url = `${media['base_url']}/source/${filename}`;
    copyToClipboard(url);
  };

  const downloadFile = filename => {
    downloadUploadedMediaFile(media.id, filename);
  };

  const removeFile = filename => {
    dispatch(removeUploadedMediaFile(media.id, filename));
  };

  return (
    <div className='freeport-media-detail'>
      <div className='card mb-3'>
        <div className='card-body'>
          <div className='d-flex justify-content-between'>
            <h4>Freeport Data</h4>
            <Button
              variant='primary'
              onClick={() => {
                setShowEditDialog(true);
              }}
            >
              Edit
            </Button>
          </div>
          <div className='detail-item'>
            <h6>Result S3 URL</h6>
            <a
              href={`https://s3.console.aws.amazon.com/s3/buckets/${s3ResultBucket}?region=us-west-2&prefix=${
                media.version && Number(media.version) > 1
                  ? `${media.customer_id}/`
                  : ''
              }${media.id}/&showversions=false`}
              target='_blank'
            >
              {`s3://${s3ResultBucket}/${
                media.version && Number(media.version) > 1
                  ? `${media.customer_id}/`
                  : ''
              }${media.id}`}
            </a>
          </div>
          <div className='detail-item'>
            <h6>Upload S3 URL</h6>
            <a
              href={`https://s3.console.aws.amazon.com/s3/buckets/${s3UploadBucket}?region=us-west-2&prefix=${media.customer_id}/${media.id}/&showversions=false`}
              target='_blank'
            >
              {`s3://${s3UploadBucket}/${media.customer_id}/${media.id}`}
            </a>
          </div>
          <div className='detail-item'>
            <h6>Freeport Database URL</h6>
            <a
              href={`https://us-west-2.console.aws.amazon.com/dynamodbv2/home?region=us-west-2#edit-item?itemMode=2&pk=${media.id}&route=ROUTE_ITEM_EXPLORER&sk=&table=${freeportMediaTable}`}
              target='_blank'
            >
              {`DynamoDB > ${freeportMediaTable} > ${media.id}`}
            </a>
          </div>
          <div className='row'>
            <div className='col-2'>
              <div className='detail-item'>
                <h6>Status</h6>
                <p className='text-capitalize'>
                  {getStatusText(media.processing_status)}
                </p>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <div className='detail-item'>
                <h6>Capture format</h6>
                <p>{currentCaptureFormat.label}</p>
              </div>
            </div>
            <div className='col'>
              <div className='detail-item'>
                <h6>Rotation angle</h6>
                <p>
                  {submit_params.rotation < 0
                    ? 'Not defined'
                    : `${submit_params.rotation}°`}
                </p>
              </div>
            </div>
            <div className='col'>
              <div className='detail-item'>
                <h6>Scale factor</h6>
                <p className='text-capitalize'>
                  {submit_params.scale_factor || submit_params.downscale_factor}
                </p>
              </div>
            </div>
            <div className='col'>
              <div className='detail-item'>
                <h6>Depth fill radius</h6>
                <p className='text-capitalize'>
                  {submit_params.erosion_radius}
                </p>
              </div>
            </div>
            <div className='col'>
              <div className='detail-item'>
                <h6>Stabilize motion?</h6>
                <p className='text-capitalize'>
                  {submit_params.stabilize_motion}
                </p>
              </div>
            </div>
            <div className='col'>
              <div className='detail-item'>
                <h6>Random seed?</h6>
                <p className='text-capitalize'>{submit_params.random_seed}</p>
              </div>
            </div>
            <div className='col'>
              <div className='detail-item'>
                <h6>NSFW filter type</h6>
                <p className='text-capitalize'>
                  {submit_params.nsfw_filter_type}
                </p>
              </div>
            </div>
            <div className='col'>
              <div className='detail-item'>
                <h6>Pipeline preset</h6>
                <p className='text-capitalize'>
                  {submit_params.pipeline_preset}
                </p>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <div className='detail-item'>
                <h6>Mask source?</h6>
                <p className='text-capitalize'>{submit_params.mask_source}</p>
              </div>
            </div>
            <div className='col'>
              <div className='detail-item'>
                <h6>Depth processing</h6>
                <p className='text-capitalize'>
                  {submit_params.depth_processing}
                </p>
              </div>
            </div>
            <div className='col'>
              <div className='detail-item'>
                <h6>Background processing</h6>
                <p className='text-capitalize'>
                  {submit_params.background_processing}
                </p>
              </div>
            </div>
            <div className='col'>
              <div className='detail-item'>
                <h6>Mask processing</h6>
                <p className='text-capitalize'>
                  {submit_params.mask_processing}
                </p>
              </div>
            </div>
            <div className='col'>
              <div className='detail-item'>
                <h6>Sound effects</h6>
                <p className='text-capitalize'>
                  {submit_params.sound_effects}
                </p>
              </div>
            </div>
            <div className='col'>
              <div className='detail-item'>
                <h6>Virtual views</h6>
                <p className='text-capitalize'>{submit_params.virtual_views}</p>
              </div>
            </div>
            <div className='col'>
              <div className='detail-item'>
                <h6>Virtual motion amount</h6>
                <p className='text-capitalize'>
                  {submit_params.virtual_motion_amount}
                </p>
              </div>
            </div>
            <div className='col'>
              <div className='detail-item'>
                <h6>Renderer type</h6>
                <p className='text-capitalize'>{submit_params.renderer_type}</p>
              </div>
            </div>
          </div>
          <div className='detail-item'>
            <h6>Error reason</h6>
            <p className='text-danger'>{media.error_message}</p>
          </div>
          <div className='row'>
            <div className='col'>
              <div className='detail-item'>
                <h6>Submit Params</h6>
                <p className=''>{JSON.stringify(submit_params)}</p>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <div className='detail-item'>
                <h6>Pose Preset</h6>
                <p className=''>
                  {media.pose_preset
                    ? JSON.stringify(media.pose_preset)
                    : 'Default'}
                </p>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-2'>
              <div className='detail-item'>
                <h6>Service Name</h6>
                <p className=''>
                  {media?.service_name}
                </p>
              </div>
            </div>
            <div className='col-2'>
              <div className='detail-item'>
                <h6>Service Version</h6>
                <p className=''>
                  {media?.service_version}
                </p>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <div className='detail-item'>
                <h6>File Map</h6>
                <p className=''>{JSON.stringify(media.file_map) || ''}</p>
              </div>
            </div>
          </div>
          {media.batch_id && (
            <div className='row'>
              <div className='col'>
                <div className='detail-item'>
                  <h6>Batch</h6>
                  <p className=''>
                    <NavLink
                      to={'/batch/' + media.batch_id}
                      className='link-info'
                    >
                      {media.batch_id}
                    </NavLink>
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='card mb-3'>
        <div className='card-body'>
          <div className='row'>
            <div className='col-md-6'>
              <h4 className='mb-1'>Uploaded Files</h4>
              <div className='uploaded-files-list'>
                <div className='card'>
                  <div className='card-body'>
                    <ul className='files-list list-group'>
                      {media &&
                        media.files &&
                        media.files.map(f => (
                          <li className='list-group-item' key={f.name}>
                            <span className='file-name'>
                              {f.name}{' '}
                              <small>({humanFileSize(f.size, true)})</small>
                            </span>
                            <div className='buttons flex'>
                              <Select
                                className='file-type-option'
                                options={FILE_TYPE_OPTIONS}
                                onChange={value =>
                                  handleChangeFileType(f.name, value.value)
                                }
                                value={FILE_TYPE_OPTIONS.find(
                                  fto =>
                                    fto.value === fileMap[f.name.toLowerCase()]
                                )}
                              />
                              <Button
                                variant='primary'
                                size='sm'
                                onClick={() => downloadFile(f.name)}
                              >
                                Download
                              </Button>
                              <Button
                                variant='danger'
                                size='sm'
                                onClick={() => removeFile(f.name)}
                              >
                                Delete
                              </Button>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
              <h4 className='mt-4 mb-1'>Source Files</h4>
              <div className='uploaded-files-list'>
                <div className='card'>
                  <div className='card-body'>
                    <ul className='files-list list-group'>
                      {media &&
                        media.source_files &&
                        media.source_files.map(f => (
                          <li className='list-group-item' key={f.name}>
                            <span>
                              {f.name}{' '}
                              <small>({humanFileSize(f.size, true)})</small>
                            </span>
                            <div className='buttons flex'>
                              <Button
                                variant='primary'
                                size='sm'
                                onClick={() => copySourceFile(f.name)}
                              >
                                Copy Download URL
                              </Button>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <h4 className='mb-1'>Upload a File</h4>
              <div className='contents'>
                <div className='mx-auto'>
                  <div className='file-uploader'>
                    <section className='container'>
                      <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        <p>
                          Drag & drop some files here, or click to select files
                        </p>
                      </div>
                      <aside>
                        <h5>Files</h5>
                        <ul>
                          {preUploadFiles.map(file => (
                            <li key={file.path}>
                              {file.path} - {humanFileSize(file.size, true)}
                            </li>
                          ))}
                        </ul>
                      </aside>
                      <div className='buttons flex'>
                        <Button
                          variant='primary'
                          onClick={() => onUploadFile(UPLOAD_TO_UPLOAD)}
                          className='me-1'
                        >
                          Upload
                        </Button>
                        <Button
                          variant='primary'
                          onClick={() => onUploadFile(UPLOAD_TO_SOURCE)}
                        >
                          Upload to source
                        </Button>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isUploading ? (
        <Modal title={'Uploading'} onClose={() => {}} size='[500px]'>
          <div className='mb-4'>
            Please wait until we finish uploading files.
          </div>
        </Modal>
      ) : (
        ''
      )}

      {showEditDialog ? (
        <AdvancedEditDialog
          media={media}
          onClose={() => {
            setShowEditDialog(false);
          }}
          onSubmit={onUpdateMedia}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export { FreeportMediaDetail };
